<template>
  <div id="review">
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">

        <router-link :to="`/review/add`">
          <vs-button class="mr-6" type="border" size="small" icon-pack="feather" icon="icon-edit" color="success">
            เพิ่มรูปภาพ</vs-button>
        </router-link>

      </div>
      <vs-table stripe max-items="20" pagination :data="listReviewData" search>
        <template slot="thead">
          <vs-th>ID</vs-th>
          <vs-th>ชื่อรูป</vs-th>
          <vs-th>หมายเหตุ</vs-th>
          <vs-th>รูป</vs-th>
          <vs-th>สถานะแสดงหน้าเว็บ</vs-th>
          <vs-th>วันที่อัพโหลด</vs-th>
          <vs-th>แก้ไขล่าสุด</vs-th>
          <vs-th></vs-th>
          <vs-th></vs-th>

        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.img_id">
              {{ tr.img_id }}
            </vs-td>
            <vs-td :data="tr.img_name">
              {{ tr.img_name }}
              <!-- {{moment(tr.trans_datetime).format('YYYY-MM-DD HH:mm:ss')  }} -->
            </vs-td>

            <vs-td :data="tr.img_info">
              {{ tr.img_info }}
            </vs-td>
            <vs-td :data="tr.img_url">
              <!-- {{tr.img_url}} -->
              <vs-button class="mr-6" type="border" size="small" color="success" @click="showIMG(tr.img_url)"> ดูรูป
              </vs-button>
            </vs-td>
            <vs-td v-if="tr.img_status == true" :data="tr.img_status">
              <p class="text-success">แสดงหน้าเว็บ</p>
            </vs-td>
            <vs-td v-else :data="tr.img_status">
              <p class="text-danger">ไม่แสดง</p>
            </vs-td>
            <vs-td :data="tr.img_datetime">
              {{ moment(tr.img_datetime).format('YYYY-MM-DD HH:mm:ss') }}
            </vs-td>
            <vs-td v-if="tr.img_last_update_datetime !== null" :data="tr.img_last_update_datetime">
              {{ moment(tr.img_last_update_datetime).format('YYYY-MM-DD HH:mm:ss') }}
            </vs-td>
            <vs-td v-else :data="tr.img_last_update_datetime">
              <p>-</p>
            </vs-td>
            <vs-td>
              <router-link v-if="$store.state.AppActiveUser.permissions.review.action" :to="`/review/edit/${tr.img_id}`">
                <vs-button class="mr-6" type="border" size="small" icon-pack="feather" icon="icon-edit" color="warning">
                  แก้ไข</vs-button>
              </router-link>
            </vs-td>
            <vs-td>
              <vs-button v-if="$store.state.AppActiveUser.permissions.review.action" class="mr-6" type="border"
                size="small" icon-pack="feather" icon="icon-edit" color="danger"
                @click="popupDeleteIMG(tr.img_id, tr.img_name, tr.img_url)"> ลบรูป</vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

    </div>

    <!-- popup_show_img -->
    <vs-popup classContent="popup-example" title="Username" :active.sync="popupShowIMG">
      <img :src="showIMGurl" :alt="showIMGurl" :srcset="showIMGurl" width="100%" height="100%">
    </vs-popup>

    <!-- popup_delete_img -->
    <vs-popup classContent="popup-example" title="Username" :active.sync="showPopupDeleteIMG">
      <div class="container text-center">
        <vs-button class="mr-6 mb-2" type="border" size="small" icon-pack="feather" icon="icon-edit" color="danger"
          @click="deleteIMG()"> ยืนยันลบรูป</vs-button>
        <vs-button class="mr-6" type="border" size="small" icon-pack="feather" icon="icon-edit" color="success"
          @click="showPopupDeleteIMG = false"> ยกเลิก</vs-button>

      </div>
    </vs-popup>
  </div>
</template>

<script>

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import axios from '../../axios'

// import moment from 'moment'
// import store from '../../store/store'

export default {
  name: 'Review',
  components: {
    vSelect,
    flatPickr,
    StatisticsCardLine
  },
  data () {
    return {
      listReviewData: [],
      popupShowIMG: false,
      showPopupDeleteIMG: false,
      showIMGurl: '',
      dataDelImgID: '',
      dataDelImgName: '',
      dataDelImgURL: '',
      resDelete: []
    }
  },
  async mounted () {
    // this.gridApi = this.gridOptions.api
    // if (window.innerWidth > 768) {
    //   this.gridApi.sizeColumnsToFit()
    // }
    await this.listImgReview()
  },
  methods: {
    async listImgReview () {
      await axios
        .get(
          'review/list'
        )
        .then(response => (this.listReviewData = response.data))
    },
    showIMG (imgURL) {
      this.showIMGurl = imgURL
      this.popupShowIMG = true

    },
    popupDeleteIMG (imgID, imgName, imgURL) {
      this.dataDelImgID = imgID
      this.dataDelImgName = imgName
      this.dataDelImgURL = imgURL
      this.showPopupDeleteIMG = true
    },
    async deleteIMG () {
      await axios
        .post('review/delete', {
          img_id: this.dataDelImgID,
          img_name: this.dataDelImgName,
          img_url: this.dataDelImgURL
        })
        .then(response => (this.resDelete = response.data))
      if (this.resDelete.status === true) {

        // setTimeout(() => {
        //   window.location.reload()
        // }, 1500)
        await this.$vs.notify({
          time: 10000,
          color: 'success',
          position: 'top-right',
          icon: 'error',
          title: this.resDelete.info.title,
          text: this.resDelete.info.info
        })
        await this.listImgReview()
        this.dataDelImgID = ''
        this.dataDelImgName = ''
        this.dataDelImgURL = ''
      } else {
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: this.resDelete.info.title,
          text: this.resDelete.info.info
        })
      }
      this.showPopupDeleteIMG = false
    }
  }
}
</script>

<style></style>
